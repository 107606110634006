<template>
  <base-section id="k-d-a-project-list" space="100" top-inner-shadow>
    <v-container>
      <v-row no-gutters justify="center" class="px-4" style="gap: 56px">
        <v-col v-for="project in m_arrProjectsLoaded" :key="project.strTitle" cols="auto">
          <v-card
            style="padding: 25px"
            class="mx-auto"
            max-width="500"
            height="100%"
            elevation="3"
            tile
            color="white"
          >
            <base-img
              :src="project.imgSrc"
              cover
              style="max-height: 280px; max-width: 100%"
              class="py-4"
            />

            <p class="kda-ts-20pt wt-semibold nunito main-bw-400--text px-3 pt-4">
              {{ project.strType }}
            </p>
            <div class="px-3 py-4" style="height: 200px">
              <h4 class="kda-ts-28pt wt-extrabold mb-4">{{ project.strTitle }}</h4>

              <p class="kda-ts-16pt ln-150 nunito main-bw-300--text truncate">
                {{ project.strDesc }}
              </p>
            </div>
            <v-btn
              color="main-r-400"
              style="color: white; width: 100%; text-transform: capitalize"
              @click="navToDetailProject(project.strLink)"
            >
              {{ m_strButtonLabel }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="12"
          md="12"
          class="my-8"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <v-btn
            v-if="m_arrProjectsLoaded.length !== arrProjects.length"
            style="color: white; text-transform: capitalize"
            color="main-bw-300"
            class="full-width"
            @click="loadMore"
          >
            {{ m_strButtonLoadMore }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDAProjectList',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  data() {
    return {
      iProjectIndex: 0,
      arrProjects: [
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: 'WayFinder',
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.home.projects.item1.desc`),
          strLink: 'wayfinder'
        },
        {
          strType: 'PT. Asuransi AXA Indonesia',
          strTitle: 'AXA',
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.home.projects.item2.desc`),
          strLink: 'axa'
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: 'Load Container',
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.home.projects.item3.desc`),
          strLink: 'load-container'
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: this.$vuetify.lang.t(`$vuetify.kda.project.detail.item-sqm-chatbot.name`),
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.project.detail.item-sqm-chatbot.subtitle`),
          strLink: 'sqm-chatbot'
        },
        {
          strType: 'PT. Citra Kreasi Makmur',
          strTitle: this.$vuetify.lang.t(`$vuetify.kda.project.detail.item-price-fetcher.name`),
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.project.detail.item-price-fetcher.subtitle`),
          strLink: 'price-fetcher'
        }
      ],
      length: 2
    };
  },

  computed: {
    m_strButtonMore() {
      return 'Lihat Lebih Banyak Proyek';
    },
    m_strButtonLabel() {
      return this.$vuetify.lang.t('$vuetify.kda.project.see');
    },
    m_strButtonLoadMore() {
      return this.$vuetify.lang.t('$vuetify.kda.project.load');
    },
    m_arrProjectsLoaded() {
      const result = this.arrProjects;

      for (let i = 0; i < result.length; i++) {
        result[i].imgSrc = require(`@/assets/photos/home-project-${i + 1}.png`);
      }

      return result.slice(0, this.length);
    }
  },

  methods: {
    loadMore() {
      if (this.length > this.arrProjects.length) return;
      this.length = this.length + 2;
    },
    navToDetailProject(link) {
      window.gtag('event', 'click', {
        'event_label': 'See Project'
      });

      this.$router.push(`detail/${link}`);
    }
  }
};
</script>

<style scoped lang="scss">
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
